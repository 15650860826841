:root {
  --blue: #1e90ff;
  --white: #ffffff;
  overflow-x: hidden;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.row-big-col-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.short-p-style {
  max-width: 250px;
  text-align: center;
}

@media (max-width: 700px) {
  .row-big-col-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

